/** @format */

import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, MARKS } from '@contentful/rich-text-types';
import { FunctionComponent as FC, ReactNode, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import theme from '../../../theme/styled-theme';
import { Container as DefaultContainer, ContainerWrapper } from '../../ui/Layout';
import { RichText } from '../../ui/RichText';
import { H1, P, Subtitle } from '../../ui/Typography';
import RectangleLandingIcon from '../../../assets/rectangle-landing.svg';
import CurlyArrowLandingIcon from '../../../assets/curly-arrow-landing.svg';
import { Icon } from '@/components/ui/Icon';
import { breakpointUpTo } from 'src/theme/mixins';

export const LandingHero: FC<Props> = ({ title }) => {
  const theme = useContext(ThemeContext);

  return (
    <ContainerWrapper as="section" $color={theme?.color.teal.dark}>
      <Container>
        <TextWrapper>
          <RichText text={title} customOptions={OPTIONS} />
        </TextWrapper>
      </Container>
      <ImageContainers>
        <RectangleLandingIconWrapper icon={RectangleLandingIcon} height={234} width={234} />
      </ImageContainers>
    </ContainerWrapper>
  );
};

type Props = {
  title: Document;
};

const RectangleLandingIconWrapper = styled(Icon)`
  position: absolute;
  top: 34px;
  left: 0;
  background-size: contain !important;
  background-repeat: no-repeat;
`;

const CurlyArrowLandingIconWrapper = styled(Icon)`
  background-size: contain !important;
  left: -20%;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  background-repeat: no-repeat;
  ${breakpointUpTo.lg`
    display: none;
    `}
`;

const ImageContainers = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const Container = styled(DefaultContainer)`
  position: relative;
  z-index: 1;
`;

const TextWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  text-wrap: balance;
  z-index: 3;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: center;

  padding-block: clamp(2rem, 1.9583rem + 0.1852vw, 2.125rem) clamp(2rem, 1.6667rem + 1.4815vw, 3rem);

  h1 {
    white-space: pre-line;
    font-size: clamp(1.688rem, 0.834rem + 3.7956vw, 3.75rem);
    line-height: clamp(2.093rem, 1.374rem + 3.1956vw, 4.25rem);
    padding-bottom: clamp(0.5rem, 0.1667rem + 1.4815vw, 1.5rem);
    em {
      font-size: clamp(2rem, 0.834rem + 3.7956vw, 4.25rem);
    }
  }

  p {
    white-space: normal;
    justify-content: center;
    max-width: clamp(20rem, 12.5rem + 33.3333vw, 42.5rem);
    font-size: clamp(1rem, 0.9167rem + 0.3704vw, 1.25rem);
    line-height: clamp(1.24rem, 0.8333rem + 0.7407vw, 1.5rem);
  }
`;

const OPTIONS: Options = {
  renderMark: {
    [MARKS.ITALIC]: (text: ReactNode) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => <H1>{children}</H1>,
    [BLOCKS.HEADING_6]: (node, children) => (
      <Subtitle as="p" $color={theme.color.white}>
        {children}
      </Subtitle>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // to avoid rendering empty paragraphs
      if (Array.isArray(children) && children.length === 1 && children[0] === '') return;
      return (
        <div style={{ position: 'relative' }}>
          <CurlyArrowLandingIconWrapper icon={CurlyArrowLandingIcon} width={111} height={103} />
          <P $color={theme.color.white}>{children}</P>
        </div>
      );
    },
  },
};
