/** @format */

import { LandingHero } from '@/components/Landing/Hero';
import { PageWrapper } from '@/components/Landing/PageWrapper';
import { CorporationLd } from '@/components/Layout/CorporationLd';
import { getCompany, getLandingFooter, getLandingNavigation, getPage, getTranslations } from '@/lib/contenful.api';
import { Company, LandingPageContent, Menu, Translations } from '@/types/page';
import { Entry } from 'contentful';
import { GetStaticProps, NextPage } from 'next';
import { usePageContent } from '@/hooks/usePageContent';
import { PageContent } from '@/components/PageContent';
import { ModalProvider } from '@/context/modalContext';
import Modal from '@/components/Landing/LandingCardsSection/Modal';
import { useLanguageSwitcherContext } from '@/context/LanguageSwitcherContext';
import { useEffect } from 'react';

const Index: NextPage<Props> = ({ navigation, content, footer, company, translations }) => {
  const { setSlugs } = useLanguageSwitcherContext();
  const pageContent = usePageContent(content);

  useEffect(() => {
    const HomeSlugs = {
      en: '',
      it: '',
      es: '',
      fr: '',
      pt: '',
    };
    setSlugs(HomeSlugs);
  }, []);

  return (
    <ModalProvider>
      <PageWrapper
        meta={content.seo.fields}
        pageType="home"
        navigation={navigation}
        footer={footer}
        company={company}
        translations={translations}
        slug={content.slug.fields.slug}
      >
        <CorporationLd />
        <LandingHero title={content.body} />
        <PageContent content={pageContent} title="Joivy Home" />
      </PageWrapper>
      <Modal />
    </ModalProvider>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const localeToFetch = () => {
    if (!locale) return 'en';
    if (locale === 'default') return 'en';
    return locale;
  };

  const navigation = await getLandingNavigation(localeToFetch());
  const content = await getPage('landingPage', localeToFetch(), '/');

  const footer = await getLandingFooter(localeToFetch());
  const company = await getCompany(localeToFetch());
  const translations = await getTranslations(localeToFetch());

  return {
    notFound: locale === 'default',
    props: {
      navigation,
      content,
      footer,
      company,
      translations,
    },
  };
};

type Props = {
  content: LandingPageContent;
  navigation: Entry<Menu>;
  footer: Entry<Menu>;
  company: Entry<Company>;
  translations: Entry<Translations>;
};

export default Index;
