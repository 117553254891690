/** @format */

import React, { useContext, useEffect, useMemo, useRef } from 'react';
import styled, { ThemeContext, keyframes } from 'styled-components';
import { useModal } from '@/context/modalContext';
import { Icon } from '@/components/ui/Icon';
import CloseIcon from '@/assets/icons/close_landing.svg';
import ModalShapeBottomRight from '@/assets/icons/modal_shape_bottom_right.svg';
import ModalShapeTopLeft from '@/assets/icons/modal_shape_top_left.svg';
import { useMediaQueryIndex } from '@/hooks/useMediaQuery';
import { breakpointUpTo } from 'src/theme/mixins';

const Modal: React.FC = () => {
  const mediaQueryIndex = useMediaQueryIndex();
  const mobile = useMemo(() => !!(mediaQueryIndex !== null && mediaQueryIndex < 2), [mediaQueryIndex]);
  const theme = useContext(ThemeContext);
  const { isModalOpen, toggleModal, modalContent } = useModal();
  const modalContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (isModalOpen && modalContentRef.current && !modalContentRef.current.contains(event.target as Node)) {
        toggleModal();
      }
    };

    if (isModalOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isModalOpen, toggleModal]);

  if (!isModalOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent $mobile={mobile} ref={modalContentRef}>
        <Icon className="ModalShapeTopLeft" icon={ModalShapeTopLeft} width={63.86} height={63.21} />
        <ModalClose>
          <IconWrapper onClick={toggleModal}>
            <Icon icon={CloseIcon} width={16} height={16} color={theme?.color.teal.dark} />
          </IconWrapper>
        </ModalClose>
        {modalContent}
        <Icon className="ModalShapeBottomRight" icon={ModalShapeBottomRight} width={80} height={90} />
      </ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background: #00000066;
  backdrop-filter: blur(4px);
  z-index: 1000;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const slideUp = keyframes`
  from {
    bottom: -100%;
  }
  to {
    bottom: 0;
  }
`;

const ModalContent = styled.div<{ $mobile: boolean }>`
  background: ${({ theme }) => theme.color.white};
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  max-width: 380px;
  position: relative;
  padding-bottom: clamp(2rem, 1.5833rem + 1.8519vw, 3.25rem);

  ${breakpointUpTo.sm`
    position: absolute; // or 'relative' depending on your layout
    bottom: -100%; // Starting position off-screen
    animation: ${slideUp} 0.2s ease-out 0.1s forwards;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;    
    max-width: none;
  `}

  .modal-badge {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0;
    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.teal.light};
    border: 1px solid ${({ theme }) => theme.color.gray.light};
  }

  h2 {
    font-family: ${({ theme }) => theme.font.body};
    font-weight: 700;
    line-height: 1.24;
    margin-bottom: 4px;
    letter-spacing: normal;
    font-size: clamp(1.688rem, 1.584rem + 0.4622vw, 2rem);
    text-align: center;
  }
  p {
    font-size: 1rem;
    letter-spacing: normal;
    line-height: 1.24;
    text-align: center;
    padding-inline: 2.875rem;
    padding-bottom: 2rem;
  }

  a {
    margin-inline: 2.875rem;
    margin-bottom: 0.25rem;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        margin-right: 0.5rem;
      }
    }
    background-color: ${({ theme }) => theme.color.gray.light};
    &:hover {
      color: ${({ theme }) => theme.color.teal.dark};
      background-color: ${({ theme }) => theme.color.landing.gray.light};
    }
  }

  .ModalShapeTopLeft {
    position: absolute;
    top: -20px;
    left: -20px;
    background-size: contain !important;
    background-repeat: no-repeat;
    ${breakpointUpTo.sm`
      top:-30px;
      left: 8px;
    `}
  }
  .ModalShapeBottomRight {
    position: absolute;
    bottom: -35px;
    right: -50px;
    z-index: -1;
    background-size: contain !important;
    background-repeat: no-repeat;
    ${breakpointUpTo.sm`
      display: none;
    `}
  }

  /* Add your styles for the modal content here */
`;

const ModalClose = styled.div`
  /* Add your styles for the modal close button here */
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
`;

const IconWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.gray.light};
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  i {
    background-color: ${({ theme }) => theme.color.landing.gray.light};
  }
  &:hover {
    background-color: ${({ theme }) => theme.color.landing.gray.light};
    color: ${({ theme }) => theme.color.white};
    /* i {
      background-color: ${({ theme }) => theme.color.landing.white}!important;
    } */
  }
`;

export default Modal;
