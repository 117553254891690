/** @format */

import { utilityGTMWsetup } from '@/lib/google-tag-manager';
import { Company, Menu, Translations } from '@/types/page';
import { Metadata } from '@/types/shared';
import { Entry } from 'contentful';
import { useRouter } from 'next/router';
import { FunctionComponent as FC, useEffect } from 'react';
import { globaltranslations, i18nContext } from '../../../context/i18nContext';
import { Footer } from '../../Layout/Footer';
import Navbar from '../../Layout/Navbar';
import MetaData from './MetaData';

export const PageWrapper: FC<Props> = ({
  children,
  meta,
  pageType,
  navigation,
  footer,
  company,
  translations,
  slug,
}) => {
  const { locale } = useRouter();

  useEffect(() => {
    utilityGTMWsetup(pageType, locale ?? 'en');
  }, [locale, slug, pageType]);

  return (
    <i18nContext.Provider value={translations.fields.translations || globaltranslations}>
      <MetaData meta={meta} noindex={pageType === 'preview' || pageType === '404'} />
      <Navbar navItems={navigation.fields.menuItems} landing />
      <main>{children}</main>
      <Footer footerItems={footer.fields.menuItems} company={company} pageType={pageType} />
    </i18nContext.Provider>
  );
};

type Props = {
  children: React.ReactNode;
  meta: Metadata;
  pageType: string;
  navigation: Entry<Menu>;
  footer: Entry<Menu>;
  company: Entry<Company>;
  translations: Entry<Translations>;
  slug: string;
};
